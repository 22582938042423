import React from "react";
import { SEO, PageLayout, Background, FoodMenu } from "@bluefin/components";
import { Header, Grid } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles } from "../utils/utils";

export default class ServicesPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      allFishermanBusinessMenuSchedule,
      allFishermanBusinessMenuCategory,
      allFishermanBusinessMenuItem,
      allFishermanBusinessMenuModifierSet,
      allFishermanBusinessMenuModifier,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout
          hero={
            <Background columns={1} stackable={true} className={"menu-hero"}>
              <Background.Color width={16} color={"white"}>
                <Header as={"h1"}>Our Services</Header>
              </Background.Color>
              <Grid
                stackable={true}
                className={"service-infos"}
                verticalAlign={"middle"}
                textAlign={"center"}
                centered={true}
              >
                <Grid.Column width={10} textAlign={"center"}>
                  <p>
                    The following prices are starting prices and estimates. A
                    variety of factors can impact pricing. For example: fleas
                    and ticks, coat type, coat condition, temperament, and
                    add-on services.
                  </p>
                  <p>
                    Dematting Pricing: Bathing and Grooming Minor tangles and
                    knots can be removed with brushing or combing. Excessive
                    matting must be removed with clipper. $1 per minute.
                  </p>
                  <p>
                    *Note that attempting to remove excessive matting by
                    brushing or combing is painful and harmful to the dogs
                    overall well-being, coat, and skin.
                  </p>
                </Grid.Column>
              </Grid>
            </Background>
          }
        >
          <Grid
            stackable={true}
            className={"menu-container"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={"16"} textAlign={"left"}>
              <FoodMenu
                header={""}
                showCategoryDropdown={false}
                showMenuLikes={false}
                centerNavigation={true}
                itemDisplayVariant={"HorizontalCard"}
                categoryDisplayVariant={"HorizontalCardRow"}
                menu={{
                  schedules: allFishermanBusinessMenuSchedule.nodes,
                  categories: allFishermanBusinessMenuCategory.nodes,
                  items: allFishermanBusinessMenuItem.nodes,
                  modifierSets: allFishermanBusinessMenuModifierSet.nodes,
                  modifiers: allFishermanBusinessMenuModifier.nodes,
                }}
                business={{
                  id: fishermanBusiness._id,
                  type: fishermanBusiness.type,
                }}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      _id
      type
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Services" }) {
      title
      components {
        fastId
        componentIdentifier
        order
        files {
          file
        }
      }
    }
    allFishermanBusinessMenuSchedule {
      nodes {
        availableDays
        categories
        _id
        name
      }
    }
    allFishermanBusinessMenuCategory {
      nodes {
        items
        description
        name
        _id
      }
    }
    allFishermanBusinessMenuItem {
      nodes {
        modifierSets
        description
        _id
        name
        image
        gatsbyImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        variations {
          _id
          menuItem
          name
          order
          price
        }
        annotations {
          likeCount
        }
        visible
        available
      }
    }
    allFishermanBusinessMenuModifierSet {
      nodes {
        _id
        minAllowed
        modifiers
        name
      }
    }
    allFishermanBusinessMenuModifier {
      nodes {
        _id
        name
        price
      }
    }
  }
`;
